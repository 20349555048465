//

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { updateIndexDBRecord } from '../../indexDB/indexdbUtilsFunctions';
import { INDEX_DB_CONFIG } from '../../indexDB/configDB';
import { submitReportError } from '../pwa-report/submitError';
import moment from 'moment';
import { HOST_API } from 'src/config';
import { getDeviceType, getUserLocation } from 'src/utils/getUserDetails';

// ----------------------------------------------------------------------

const initialState = {
  // 1. get inventory types
  isLoading: false,
  error: null,
  succMsg: null,
  errMsg: null,
  inventoryTypes: [],

  // 2. crearte
  isCreating: false,
  inventoryCreateSuccMsg: null,
  inventoryCreateErrMsg: null,

  // 3. get inventories
  isLoadingInventories: false,
  inventories: [],
  getInventoriesError: null,
};

const slice = createSlice({
  name: 'scoutInventory',
  initialState,
  reducers: {
    // 1. get inventory types
    startLoading(state) {
      state.isLoading = true;
      state.succMsg = null;
      state.errMsg = null;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.errMsg = action.payload.message;
    },

    getInventoryTypesSuccess(state, action) {
      state.isLoading = false;
      state.inventoryTypes = action.payload;
      state.succMsg = action.payload.message;
    },

    // 2. create inventory
    startCreateInventoryLoading(state, action) {
      state.isCreating = true;
      state.inventoryCreateSuccMsg = null;
      state.inventoryCreateErrMsg = null;
    },

    createInventorySuccess(state, action) {
      state.isCreating = false;
      state.inventoryCreateSuccMsg = action.payload.message;
      state.inventoryCreateErrMsg = null;
    },

    createInventoryError(state, action) {
      state.isCreating = false;
      state.inventoryCreateSuccMsg = null;
      state.inventoryCreateErrMsg = action.payload.message;
    },

    // 3. get all inventories
    getAllInventoriesLoading(state, action) {
      state.isLoadingInventories = true;
      state.getInventoriesError = null;
    },

    getAllInventoriesSuccess(state, action) {
      state.isLoadingInventories = false;
      state.inventories = action.payload;
    },

    getAllInventoriesError(state, action) {
      state.isLoadingInventories = false;
      state.getInventoriesError = action.payload?.message ?? '';
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// 1. get inventory types
export function getInventoryTypes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/v1/inventory-types');

      if (response?.data?.status === 1) {
        if (Array.isArray(response?.data?.data)) {
          await updateIndexDBRecord(
            INDEX_DB_CONFIG.scoutInventoryTypes.storeObject,
            response.data.data
          );

          dispatch(slice.actions.getInventoryTypesSuccess(response.data.data));
        } else {
          console.error('invalid API response of v1/inventory-types');
        }
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// 2. create inventory
export function createInventory(data) {
  return async () => {
    dispatch(slice.actions.startCreateInventoryLoading());
    try {
      const response = await axios.post('/api/v1/scout-inventory', data, {
        headers: {
          ContentType: 'multipart/form-data',
        },
      });

      if (response?.data?.status === 1) {
        dispatch(slice.actions.createInventorySuccess(response.data));
      } else {
        dispatch(slice.actions.createInventoryError(response.data));
      }
    } catch (error) {

      function formDataToJson(formData) {
        const jsonObject = {};
        formData.forEach((value, key) => {
          if (jsonObject[key]) {
            jsonObject[key] = [].concat(jsonObject[key], value);
          } else {
            jsonObject[key] = value;
          }
        });
        return jsonObject;
      }

      const clientInfo = {
        device: getDeviceType(),
        location: await getUserLocation()
      }

      const formData = new FormData();
      formData.append('method', "POST");
      formData.append('payload', JSON.stringify(formDataToJson(data)));
      formData.append('response_code', error?.status);
      formData.append('response_body', JSON.stringify(error?.data));
      formData.append('error_type', error?.statusText);
      formData.append('error_occurred_at', moment().format('YYYY-MM-DD HH:mm:ss'));
      formData.append('url', `${HOST_API}api/v1/scout-inventory`);
      formData.append('app_version', "1.0.0");
      formData.append('client_info', JSON.stringify(clientInfo));

      dispatch(submitReportError(formData));
      dispatch(slice.actions.createInventoryError(error?.data));
    }
  };
}

// 3. get existing inventory
export function getAllInventories(data) {
  return async () => {
    dispatch(slice.actions.getAllInventoriesLoading());
    try {
      const response = await axios.get('/api/v1/scout-inventory');

      if (response?.data?.status === 1) {
        if (Array.isArray(response?.data?.data)) {
          await updateIndexDBRecord(
            INDEX_DB_CONFIG.scoutInventories.storeObject,
            response.data.data
          );

          dispatch(slice.actions.getAllInventoriesSuccess(response.data.data));
        } else {
          console.error('invalid API response of GET: v1/scout-inventory');
        }
      } else {
        dispatch(slice.actions.getAllInventoriesError(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.getAllInventoriesError(error));
    }
  };
}
